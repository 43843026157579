import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {POST_DEMANDE_RAPPEL} from "../../../actions/app/demandeRappel/demandeRappel.actions";
import {setError, setLoader} from "../../../actions/app/ui/ui.actions";
import {formatDataForApi} from "./formatDataForApi";
import {setNotification} from "../../../actions/core/notifications/notifications.actions";
import {reset} from "redux-form";
import {FILL} from "../../../actions/app/fill/fill.actions";
import {analyticsSetEvent} from "formulaire-trottinette/src/redux/actions/core/analytics/analytics.actions.js";

export const demandeRappelMiddleware = () => next => action => {
    next(action)

    const { payload } = action

    switch (action.type){

        case POST_DEMANDE_RAPPEL:
            const body = formatDataForApi(payload.body)

            next([
                apiRequest({body: body, method: 'POST', url: payload.data, entity: POST_DEMANDE_RAPPEL}),
                setLoader({state: true, entity: POST_DEMANDE_RAPPEL})
            ])

            break

        case `${POST_DEMANDE_RAPPEL} ${API_SUCCESS}`:
            next([
                analyticsSetEvent({event: 'callbackConfirmation', ap1: '43397'}), //callbackConfirmation uniquement en AMO
                setNotification({
                    entity: POST_DEMANDE_RAPPEL,
                    html: '<div><p>Merci, votre demande a bien été prise en compte</p><p>Un gestionnaire dédié vous recontactera dans les délais demandés.</p></div>',
                    type: 'success',
                    title: 'Merci !',
                    confirmButtonText: 'J\'ai compris',
                }),
                setError({state: false, entity: POST_DEMANDE_RAPPEL}),
                setLoader({state: false, entity: POST_DEMANDE_RAPPEL}),
                reset('demande-rappel')
            ])
            break

        case `${POST_DEMANDE_RAPPEL} ${API_ERROR}`:
            next([
                setNotification({entity: POST_DEMANDE_RAPPEL, title: 'Attention', confirmButtonText: 'Continuer', icon: 'error', text: 'Une erreur est survenue.'}),
                setError({state: true, entity: POST_DEMANDE_RAPPEL}),
                setLoader({state: false, entity: POST_DEMANDE_RAPPEL})
            ])
            break

        default:
            return null
    }
}
