import {POST_INIT, INIT, setInitSession} from '../../../actions/app/initSession/initSession.actions'
import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setLoader, setMaxStep, setStep} from '../../../actions/app/ui/ui.actions'
import _ from 'lodash'
import {clearLocalStorage, setLocalStorage} from "../../../actions/core/localStorage/localStorage.actions";
import moment from "moment";
import {APP_NAME} from '@amo/core/constants';
import { getSlugStep } from '../../../../utils/function'
// import {analyticsSetEvent} from "../../../actions/core/analytics/analytics.actions.js";
// import queryString from "query-string";

export const initSessionMiddleware = () => next => action => {
    next(action)

    const { payload } = action
    let nextActions = []

    switch (action.type){

        case POST_INIT:
            let body = payload.body

            /* Verify expire token 1h OU qu'il y a une clé dans l'url */
            if ((body.token && localStorage.getItem(APP_NAME) && moment(JSON.parse(localStorage.getItem(APP_NAME)).expire).add(1, 'hours') < moment()) || body.key){
                body.token = null
            }

            next([
                apiRequest({body, method: 'POST', url: payload.data, entity: INIT, otherData: body.key}),
                setLoader({state: true, entity: INIT})
            ])
            break

        case `${INIT} ${API_SUCCESS}`:
            const {state, token, data:{ source }, whitelabel} = action.payload.data
            const local = JSON.parse(window.localStorage.getItem(`${APP_NAME}-source`))

            nextActions = [
                clearLocalStorage({entity: INIT}),
                setLocalStorage({entity: INIT, storage: { token }}),
            ];

            // /* Matomo - plus utilisé */
            // window._paq.push([
            //     'trackEvent',
            //     'infos',
            //     'source',
            //     source
            // ])

            /* Verifie la source pour conserver le canal d'origine et si courtier on le met a jour */
            if ((local && local.source) !== source || (source === 'courtier' && action.payload.meta.otherData)){
                window.localStorage.setItem(`${APP_NAME}-source`, JSON.stringify({source, key: action.payload.meta.otherData }))
            }

            /* si la source n'est pas le direct on remplace les variables par le WhiteLabel */
            const couleurSite = (source === 'site' && payload.data.apporteur.codeBelair === '43397')

            if (!couleurSite){
                for (let [key, value] of Object.entries(whitelabel)) {
                    if (_.startsWith(key, 'style_color')){
                        document.documentElement.style.setProperty(`--${key}`, value)
                    }
                }
            }

            // En attente de validation et d'acotivation coté Nicolas
            // if (queryString.parse(window.location.search).paid === '1' && action.payload.data?.data?.Devis ) {
            //     nextActions.push(
            //         analyticsSetEvent({
            //             event: 'purchase',
            //             ap1: action.payload.data?.data?.DemandeTarif?.Police?.IdApporteur1,
            //             datas: {
            //                 ecommerce: {
            //                     currency: 'EUR',
            //                     value: action.payload.data.data.Devis.Formule.PrimeAnnuelleFracAnnuel,
            //                     transaction_id: action.payload.data.data.Contrat.Police || '-',
            //                     coupon: action.payload.data?.Tarif?.CodePromo?.Eligible ? action.payload.data?.DemandeTarif?.CodePromo?.CodePromo : '',
            //                     phoneNumber : action.payload.data?.data?.DemandeTarif?.ListePersonnes[0]?.TelPortable.replace(/\s/g, ''),
            //                     birthDate : action.payload.data?.data?.DemandeTarif?.ListePersonnes[0]?.DateNaissance ? moment(action.payload.data.data.DemandeTarif.ListePersonnes[0].DateNaissance, 'DD/MM/YYYY').format('YYYYMMDD') : null,
            //                     firstName : _.toLower(action.payload.data?.data?.DemandeTarif?.ListePersonnes[0]?.Prenom),
            //                     lastName : _.toLower(action.payload.data?.data?.DemandeTarif?.ListePersonnes[0]?.Nom),
            //                     street : `${action.payload.data?.data?.DemandeTarif?.ListePersonnes[0]?.Adresse1}${action.payload.data?.data?.DemandeTarif?.ListePersonnes[0]?.Adresse2 ? ` ${action.payload.data?.data?.DemandeTarif?.ListePersonnes[0]?.Adresse2}` : ''}`,
            //                     zipCode : action.payload.data?.data?.DemandeTarif?.ListePersonnes[0]?.CP,
            //                     city : _.lowerCase(action.payload.data?.data?.DemandeTarif?.ListePersonnes[0]?.Ville).replace(/\s/g, ''),
            //                     items: [{
            //                         item_name: `Assurance Velo`,
            //                         price: action.payload.data.data.Devis.Formule.PrimeAnnuelleFracAnnuel,
            //                         item_category: 'Velo',
            //                         item_variant: action.payload.data.data.DemandeTarif.Police.FormuleChoisie,
            //                         quantity: '1'
            //                     }]
            //                 }
            //             }
            //         })
            //     )
            // }

            switch (state) {
                /* Nouveau devis */
                case 'new':
                    nextActions = nextActions.concat([
                        setStep({step: getSlugStep('votre-velo')}),
                        setInitSession({init: payload.data}),
                        setError({state: false, entity: INIT}),
                        setLoader({state: false, entity: INIT})
                    ])
                    break

                /* Etape de tarif avant paiement */
                case 'tarif':
                    nextActions = nextActions.concat([
                        setStep({step: getSlugStep('votre-tarif')}),
                        setMaxStep({maxStep: getSlugStep('votre-tarif')}),
                        setInitSession({init: payload.data}),
                        setError({state: false, entity: INIT}),
                        setLoader({state: false, entity: INIT})
                    ])
                    break
                case 'devis':
                    if (payload.data.data.source === 'site'){
                        nextActions = nextActions.concat([
                            setStep({step: getSlugStep('votre-recapitulatif')}),
                            setMaxStep({maxStep: getSlugStep('votre-recapitulatif')}),
                            setInitSession({init: payload.data}),
                            setError({state: false, entity: INIT}),
                            setLoader({state: false, entity: INIT})
                        ])
                    } else {
                        nextActions = nextActions.concat([
                            setStep({step: getSlugStep('votre-velo')}),
                            setMaxStep({maxStep: getSlugStep('votre-velo')}),
                            setInitSession({init: payload.data}),
                            setError({state: false, entity: INIT}),
                            setLoader({state: false, entity: INIT})
                        ])
                    }

                    break

                /* save_devis fait, en attente du paiement */
                case 'contrat_en_attente_de_paiement':
                    nextActions = nextActions.concat([
                        setStep({step: getSlugStep('paiement')}),
                        setMaxStep({maxStep: getSlugStep('paiement')}),
                        setInitSession({init: payload.data}),
                        setError({state: false, entity: INIT}),
                        setLoader({state: false, entity: INIT})
                    ])
                    break

                /* retour du paiement en attente de la validation pour signature */
                case 'contrat_en_cours_de_validation':
                    nextActions = nextActions.concat([
                        setStep({step: getSlugStep('validation')}),
                        setMaxStep({maxStep: getSlugStep('validation')}),
                        setInitSession({init: payload.data}),
                        setError({state: false, entity: INIT}),
                        setLoader({state: false, entity: INIT})
                    ])
                    break

                /* save_contrat fait */
                case 'contrat':
                    /* Vérifie qu'une signature est en cours */
                    let step = payload.data.signatures.length > 0 &&
                    payload.data.signatures[0].refused === false &&
                    payload.data.signatures[0].signed === false && payload.data.signatures[0].started === false ?
                        getSlugStep('signature')
                        : getSlugStep('envoi-des-pieces');

                    if(payload.data.data.source === 'courtier' || payload.data.data.source === 'conseiller'){
                        step = getSlugStep('envoi-des-pieces');
                    }

                    nextActions = nextActions.concat([
                        setStep({step: step}),
                        setMaxStep({maxStep: step}),
                        setInitSession({init: payload.data}),
                        setError({state: false, entity: INIT}),
                        setLoader({state: false, entity: INIT})
                    ])
                    break

                default:
                    return null
            }
            next(nextActions)
            break

        case `${INIT} ${API_ERROR}`:
            next([
                clearLocalStorage({entity: INIT}),
                setError({state: true, entity: INIT}),
                setLoader({state: true, entity: INIT})
            ])
            break

        default:
            return null
    }
}
