import {connect} from 'react-redux'
import {compose} from 'redux'
import moment from "moment";
import InitFormulaire from "./InitFormulaire";
import {formValueSelector, getFormValues, reduxForm} from "redux-form";
import RouteStep from "./RouteStep";
import {
    getLoadingEntity,
    getMaxStep,
    getPrixTotal,
    getPrixTotalPromo,
    getStep
} from "../../../../redux/selectors/ui/ui.selectors";
import {setStep} from "../../../../redux/actions/app/ui/ui.actions";
import {API_URL_WS_FORMULAIRE} from '@amo/core/constants';
import {
    getFormuleChoisiePoliceDemandeTarifInit,
    getPersonneMoraleDemandeTarifInit,
    getToken,
    getCivilitePersonneMoraleTarif,
    getOptinNewsletterDemandeDevisInit,
    getOptinUtilisationDonneesDemandeDevisInit,
    getNomPersonneMoraleTarif,
    getTauxApporteur,
    getHonorairesApporteur,
    getDateNaissanceConducteurTarif,
    getNomConducteurTarif,
    getPrenomConducteurTarif,
    getAdresse1ConducteurTarif,
    getAdresse2ConducteurTarif,
    getVilleConducteurTarif,
    getCPConducteurTarif,
    getTelPortableConducteurTarif,
    getEmailConducteurTarif,
    getCiviliteConducteurTarif,
    getDateMECVehiculeInit,
    getDesignationVehiculeInit,
    getGenreVehiculeVehiculeInit,
    getValeurVehiculeVehiculeInit,
    getSignatureEmailDemandeContratInit,
    getSignaturePhoneDemandeContratInit,
    getNomTitulaireDemandeContratInit,
    getadrIBANDemandeContratInit,
    getModePaiementComptantDemandeContratInit,
    getcpIBANDemandeContratInit,
    getvilleIBANDemandeContratInit,
    getVINDemandeContratInit,
    getIBANDemandeContratInit,
    getBICDemandeContratInit,
    getDevoirVolDevoirConseilDemandeTarifInit,
    getMotifAvenantInit,
    getActeGestionDemandeTarifInit,
    getApporteurCanUseRecueilBesoin,
    getCodeBelairApporteur1Init,
    getBirthCountryConducteurTarif,
    getBirthCityConducteurTarif,
    getBirthNameConducteurTarif,
    getTiersPayeurLastNameDemandeContratInit,
    getTiersPayeurDemandeContratInit,
    getTiersPayeurBirthNameDemandeContratInit,
    getTiersPayeurFirstNameDemandeContratInit,
    getTiersPayeurBirthDateDemandeContratInit,
    getTiersPayeurBirthCountryDemandeContratInit,
    getTiersPayeurBirthCityDemandeContratInit,
    getTiersPayeurRelationshipDemandeContratInit,
    getTiersPayeurThirdPayerReasonDemandeContratInit,
    getNumeroClientsSouscripteurDemandeContratInit, getPoliceContratInit
} from "../../../../redux/selectors/initSession/initSession.selectors";
import {postFill} from "../../../../redux/actions/app/fill/fill.actions";
import {getSource} from "../../../../redux/selectors/initSession/initSession.selectors";
import _ from 'lodash'
import {TARIF} from "../../../../redux/actions/app/tarif/tarif.actions";
import {normalizeTel} from "@amo/core/utils/normalize.js";
import {analyticsSetEvent} from "../../../../redux/actions/core/analytics/analytics.actions.js";
import {getPrixTotalAnnuel} from "../../../../redux/selectors/ui/ui.selectors.js";
import {getStepBySlug, calculRoutesByRecueilBesoin, getStepSlug} from "../../../../utils/function.js";

const mapStateToProps = state => {
    const selector = formValueSelector('devisVae')
    const loadingTarif = getLoadingEntity(state, TARIF) === false
    const source = getSource(state);
    const data = getFormValues('devisVae')(state);

    return {
        data: data,
        source: source,
        needRDB: selector(state, 'DemandeTarif[UseRecueilBesoin]'),
        isAvenant: getActeGestionDemandeTarifInit(state) === 'AV',
        routes: calculRoutesByRecueilBesoin(selector(state, 'DemandeTarif[UseRecueilBesoin]'), RouteStep),
        step: getStep(state),
        slug: getStepSlug(getStep(state)),
        maxStep: getMaxStep(state),
        query: `${API_URL_WS_FORMULAIRE}/fill/${getToken(state)}`,
        tarifLoaded: loadingTarif,
        prixTotal: loadingTarif ? getPrixTotal(state) : null,
        prixTotalPromo: loadingTarif ? getPrixTotalPromo(state) : null,
        apporteur: getCodeBelairApporteur1Init(state),
        prixTotalAnnuel: getStepBySlug('votre-tarif') >= getStep(state) && data?.DemandeTarif?.Police?.FormuleChoisie ? getPrixTotalAnnuel(state) : undefined,
        numeroContrat: getPoliceContratInit(state),
        initialValues:{
            redirection: false,
            civilitePrincipale: !_.isEmpty(getPersonneMoraleDemandeTarifInit(state)) ? '3' : String(getCiviliteConducteurTarif(state)),
            "postalCodeCity-lieuStationnement": !!getCPConducteurTarif(state),
            "postalCodeCity-villeIban": true,
            DemandeDevis: {
                OptinNewsletter: getOptinNewsletterDemandeDevisInit(state),
                OptinUtilisationDonnees: getOptinUtilisationDonneesDemandeDevisInit(state)
            },
            DemandeTarif:{
                UseRecueilBesoin: (getSource(state) === 'courtier' && !getApporteurCanUseRecueilBesoin(state)) || !_.includes(["43397", "484568", "479835", "479846", "111619", "323284", "25399"], getCodeBelairApporteur1Init(state)) ? 'non' : undefined,
                Police: {
                    FractionnementChoisi: 'M',
                    FormuleChoisie: getFormuleChoisiePoliceDemandeTarifInit(state),
                    Honoraires: getHonorairesApporteur(state),
                    TauxApporteur1: getTauxApporteur(state),
                    MotifAvenant: getMotifAvenantInit(state)
                },
                Vehicule: {
                    FamilleProduit: 'Velo',
                    DateMEC: getDateMECVehiculeInit(state) ? moment(getDateMECVehiculeInit(state), 'DD/MM/YYYY') : moment(),
                    Designation: getDesignationVehiculeInit(state),
                    GenreVehicule: getGenreVehiculeVehiculeInit(state),
                    ValeurVehicule: getValeurVehiculeVehiculeInit(state)
                },
                DevoirConseil: {
                    DevoirIC: getDevoirVolDevoirConseilDemandeTarifInit(state),
                },
                ListePersonnes:[
                    {
                        TypePersonne: 'P',
                        RoleConducteur: 'P',
                        Souscripteur: !_.isEmpty(getPersonneMoraleDemandeTarifInit(state)) ? false : true,
                        DateNaissance: getDateNaissanceConducteurTarif(state) ? moment(getDateNaissanceConducteurTarif(state), 'DD/MM/YYYY') : undefined,
                        Nom: getNomConducteurTarif(state),
                        Prenom: getPrenomConducteurTarif(state),
                        Adresse1: getAdresse1ConducteurTarif(state),
                        Adresse2: getAdresse2ConducteurTarif(state),
                        Ville: getVilleConducteurTarif(state),
                        CP: getCPConducteurTarif(state),
                        TelPortable: normalizeTel(getTelPortableConducteurTarif(state)),
                        Email: getEmailConducteurTarif(state),
                        Civilite: getCiviliteConducteurTarif(state),
                        BirthCountry: getBirthCountryConducteurTarif(state),
                        BirthCity: getBirthCityConducteurTarif(state),
                        BirthName: getBirthNameConducteurTarif(state),
                        NumeroClient: getNumeroClientsSouscripteurDemandeContratInit(state),
                    },
                    !_.isEmpty(getPersonneMoraleDemandeTarifInit(state)) ? {
                        Souscripteur: true,
                        TypePersonne: 'M',
                        RoleConducteur: 'N',
                        Civilite: getCivilitePersonneMoraleTarif(state),
                        Nom: getNomPersonneMoraleTarif(state),
                        NumeroClient: getNumeroClientsSouscripteurDemandeContratInit(state),
                    } : undefined
                ],
            },
            DemandeContrat: {
                Signature: true,
                NomTitulaire: getNomTitulaireDemandeContratInit(state) || `${getNomConducteurTarif(state)} ${getPrenomConducteurTarif(state)}`,
                adrIBAN: getadrIBANDemandeContratInit(state) || getAdresse1ConducteurTarif(state),
                villeIBAN: getvilleIBANDemandeContratInit(state) || getVilleConducteurTarif(state),
                cpIBAN: getcpIBANDemandeContratInit(state) || getCPConducteurTarif(state),
                SignatureEmail: getSignatureEmailDemandeContratInit(state),
                SignaturePhone: getSignaturePhoneDemandeContratInit(state),
                ModePaiementTerme: 'P',
                ModePaiementComptant: getActeGestionDemandeTarifInit(state) === 'AV' ? 'C' : getModePaiementComptantDemandeContratInit(state),
                DateHeureEffet: moment().add(30, 'minutes').format('DD/MM/YYYY HH:mm'),
                VIN: getVINDemandeContratInit(state),
                IBAN: getIBANDemandeContratInit(state),
                BIC: getBICDemandeContratInit(state),
                TiersPayeur: !_.isEmpty(getTiersPayeurDemandeContratInit(state)) ? {
                    Type: 'P',
                    LastName: getTiersPayeurLastNameDemandeContratInit(state),
                    BirthName: getTiersPayeurBirthNameDemandeContratInit(state),
                    FirstName: getTiersPayeurFirstNameDemandeContratInit(state),
                    BirthDate: getTiersPayeurBirthDateDemandeContratInit(state),
                    BirthCountry: getTiersPayeurBirthCountryDemandeContratInit(state),
                    BirthCity: getTiersPayeurBirthCityDemandeContratInit(state),
                    Relationship: getTiersPayeurRelationshipDemandeContratInit(state),
                    ThirdPayerReason: getTiersPayeurThirdPayerReasonDemandeContratInit(state)
                } : {}
            }
        }
    }
}

const mapDispatchToProps = {
    setStep,
    postFill,
    analyticsSetEvent
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { step, maxStep, query, apporteur } = stateProps
    const { postFill, setStep } = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        nextStep: form => postFill({step: step + 1, maxStep, ap1: apporteur, form, query}),
        previousStep: () => {
            window.scroll(0,0)
            setStep({step: step - 1})
        }
    }
}

const InitFormulaireContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'devisVae',
        destroyOnUnmount: false,
        forceUnregisterOnUnmount: true,
    })
)(InitFormulaire)

export default InitFormulaireContainer
