import {setAnalytics} from '../../../actions/core/analytics/analytics.actions'
import {ANALYTICS_SET_EVENT} from "../../../actions/core/analytics/analytics.actions.js";

export const analyticsMiddleware = ({dispatch}) => next => action => {
    next(action);

    switch (action.type) {
        case ANALYTICS_SET_EVENT:
            const {event, datas, ap1} = action.meta.analytics;
            window.dataLayer = window.dataLayer || [];

            if (ap1 && String(ap1) === '43397') {
                if (datas) {
                    dataLayer.push({
                        'event': event,
                        ...datas
                    });
                } else {
                    dataLayer.push({'event': event});
                }
            }
            break;

        default:
            if (action.meta && action.meta.analytics) {

                dispatch(setAnalytics({entity: action.meta.entity}))

                const {event, actionGoogle, libelle, categorie, valeur, unique} = action.meta.analytics

                let found = unique || false

                if (unique) {
                    found = window.dataLayer.some((el) => {
                        return el.libelle === libelle
                    })
                }

                if (!found) {
                    window.dataLayer.push({
                        event,
                        'action': actionGoogle,
                        libelle,
                        categorie,
                        valeur
                    })
                }

                /*const datalayer = window.dataLayer

                const env = _.find(datalayer, 'environnement') ? _.find(datalayer, 'environnement') : {environnement: 'production'}

                if (!_.find(datalayer, 'environnement')){
                    window.dataLayer.push(env)
                }

                if ((env.environnement === 'recette' || env.environnement === 'development' ) && !found){
                    let body = `
                <p><b>event :</b> ${event}</p>
                <p><b>action :</b> ${actionGoogle}</p>
               `
                    if (libelle){
                        body = `${body} <p><b>libelle :</b> ${libelle}</p>`
                    }
                    if(categorie) {
                        body = `${body} <p><b>categorie :</b> ${categorie}</p>`
                    }
                    if(valeur) {
                        body = `${body} <p><b>valeur :</b> ${valeur}</p>`
                    }

                    dispatch(setNotification({entity: `${action.meta.entity}`, title: ANALYTICS, body ,type: 'info'}))
                }*/
            }
            break;
    }
}
