import opinions from '@amo/core/assets/images/icons/opinions_assurances.svg';
import '../tarif.scss';
import velo from "@amo/core/assets/images/produits/velo.svg";
import TableFormule from "./TableFormule/TableFormule.jsx";
import Options from "../Options/Options.jsx";
import CodePromoContainer from "../CodePromo/CodePromoContainer.js";
import SouscrireContainer from "../Souscrire/SouscrireContainer.js";
import {Field} from "redux-form";
import { required } from '@amo/core/utils/validateField'
import {QuestionLabel} from "@amo/core/components/containers";
import SlideContainer from "../../../Slide/SlideContainer.js";

const TableauGarantiesDesktop = (props) => {
    const { vehicule, formuleSelectionnee, setStep, honorairesMax, isDirect, isAvenant, apporteur } = props;

    return (
        <>
            <div className={'d-none d-lg-block'}>
                <div className={'row bg-grey'}>
                    <div
                        className={`bg-blue rounded-bottom-medium p-5 header-tarif`}
                    >
                        <p className={'text-white f-32 fw-bold p-5 pt-4 text-start'}>
                                <span className={'d-block mb-4'}>
                                    Voici nos recommandations personnalisées <br/> pour assurer votre vélo {vehicule.Designation}
                                </span>
                            <img src={opinions} alt={'Opinions Assurances'} />
                        </p>
                    </div>
                </div>
            </div>
            <div className={'bg-grey d-none d-lg-block'}>
                <div className={'container'}>
                    <div className="row justify-content-between position-relative">
                        <div className="col-12 d-flex">
                            <div className={'px-4 py-3 bg-white mt-3 rounded position-absolute bloc-vehicule shadow'}>
                                <p className={'f-14 text-dark text-start fw-bold'}>
                                    Votre deux-roues{' '}
                                    <span
                                        className={'text-secondary f-12 float-end cursor-pointer underline-link'}
                                        onClick={() => setStep(0)}
                                    >
                                        modifier
                                    </span>
                                </p>
                                <div className={'row mt-2'}>
                                    <div className="col-3">
                                        <img
                                            src={velo}
                                            alt="Véhicule"
                                        />
                                    </div>

                                    <div className="col-7 text-start">
                                        <p className={'m-0 fw-bold pt-1'}>
                                            {vehicule.Designation}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <TableFormule {...props} />
                        </div>
                        <div className={'col-12 pb-4'}>

                            <Options {...props} />

                            <CodePromoContainer {...props} />

                            {
                                !isDirect && !isAvenant && apporteur !== "43397" && <>
                                    <QuestionLabel className={'f-16 mt-4 text-center col-12'}>Vos frais de dossier<sup className={'text-danger'}>*</sup></QuestionLabel>
                                    <div className={'row'}>
                                        <div className={'col-12 col-md-8 offset-md-2'}>
                                            <Field
                                                component={SlideContainer}
                                                name={'DemandeTarif[Police][Honoraires]'}
                                                step={1}
                                                min={0}
                                                max={honorairesMax}
                                                validate={required}
                                                maxBackground={'bg-success'}
                                                minBackground={'bg-success'}
                                                minColor={'text-white'}
                                                maxColor={'text-white'}
                                                symbole={'€'}
                                            />
                                        </div>
                                    </div>
                                </>
                            }

                            <SouscrireContainer formuleChoisie={formuleSelectionnee} {...props} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TableauGarantiesDesktop;
